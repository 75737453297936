import React from 'react';
import { motion } from 'framer-motion';

const Hikayemiz = () => {
    const containerVariants = {
        hidden: { opacity: 0, scale: 0.95 },
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                duration: 1.5,
                staggerChildren: 0.5,
            },
        },
        exit: {
            opacity: 0,
            scale: 0.95,
            transition: { duration: 1.5 },
        },
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 1.5,
            },
        },
        exit: {
            opacity: 0,
            y: 50,
            transition: { duration: 1.5 },
        },
    };

    return (
        <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={containerVariants}
        >
            <div>
                <motion.img
                    src='/images/banner/hikayemiz.jpg'
                    className='h-[48vh] w-full object-cover'
                    variants={itemVariants}
                />
            </div>
            <div className='grid grid-cols-7 bg-brownGrayBg'>
                <div className='col-span-7 md:col-span-4 md:grid md:grid-cols-4 py-20 px-5 md:px-0'>
                    <motion.div
                        className='col-start-2 col-span-3 text-22 space-y-5'
                        variants={containerVariants}
                    >
                        <motion.h2 className='text-54 text-textBrown2' variants={itemVariants}>
                            Hikayemiz
                        </motion.h2>
                        <motion.p variants={itemVariants}>
                            Nazilli'nin bereketli köylerinde, doğanın eşsiz güzellikleri arasında serpilip
                            büyüyen ballı incirlerin hikayesi, yüzyıllardır süregelen bir mirasın izlerini taşır.
                            Dağların serin esintileriyle, güneşin cömert ışıklarıyla olgunlaşan bu özel
                            incirler, köy halkının emekleriyle birleşerek adeta yaradanın birer armağanı
                            haline gelir.
                        </motion.p>
                        <motion.p variants={itemVariants}>
                            Her bir meyve, Nazilli'nin verimli topraklarında kök salmış geleneklerin ve
                            mükemmel doğanın izlerini taşır. İdealist bir öğretmen ve girişimci bir ruha
                            sahip olan marka kurucumuz, bu topraklarda doğup büyüyen, çocukluğu
                            Nazilli'nin meyve bahçelerinde, ballı incirlerin gölgesinde geçen biridir. Geçen
                            yılların ardından, modern şehirlerin telaşlı yaşamına karışan bu kişi, doğup
                            büyüdüğü toprakların özlemiyle, Nazilli'nin doğallığını ve saflığını şehir
                            insanlarına ulaştırmak için bir hayal kurdu. Bu hayal, köy halkının binbir
                            emekle yetiştirdiği sağlıklı ve doğal ballı incirleri, endüstriyel ürünlerden uzak
                            duran sizlere, yani sağlığını önemseyen bilinçli tüketicilere sunma arzusunu
                            taşıyordu.
                        </motion.p>
                        <motion.p variants={itemVariants}>
                            İşte bu arzuyla doğan markamız, Nazilli’nin köklerinden gelen bu eşsiz lezzeti
                            sizlerle buluşturuyor. Doğal ve sağlıklı ballı incirlerimizin her birinde, bu
                            toprakların hikayesini, köy halkının emeğini ve yaradanın cömertliğini
                            hissedeceksiniz.
                        </motion.p>
                        <motion.p variants={itemVariants}>
                            KÖYİÇİ Markası, Nazilli’nin bereketli topraklarından sizlerin sofralarına uzanan
                            bir köprü, sağlıklı ve doğal ürünleriyle şehrin kalabalığına doğallık katan bir
                            dokunuş. Şimdi, bu büyülü lezzeti tatma zamanı. Afiyet şifa olsun!
                        </motion.p>
                    </motion.div>
                </div>
                <div className='col-span-7 md:col-span-3 relative'>
                    <motion.div
                        className='col-start-2 col-span-3'
                        initial="hidden"
                        whileInView="visible"
                        exit="exit"
                        variants={containerVariants}
                    >
                        <motion.img
                            src='/images/sari-lop/back.png'
                            className='w-full'
                            variants={itemVariants}
                        />
                        <motion.img
                            src='/images/hikayemiz/1.png'
                            className='absolute top-0 left-0 w-full object-contain'
                            variants={itemVariants}
                            initial={{ opacity: 0, y: -50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1.5, delay: 0.5 }}
                        />
                        <motion.img
                            src='/images/hikayemiz/2.png'
                            className='absolute top-0 left-0 w-full object-contain'
                            variants={itemVariants}
                            initial={{ opacity: 0, y: -50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1.5, delay: 1 }}
                        />
                    </motion.div>
                </div>
            </div>
        </motion.div>
    );
};

export default Hikayemiz;
