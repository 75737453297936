import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const Section3 = () => {
  const containerVariants = {
    hidden: { opacity: 0, scale: 0.95 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
        staggerChildren: 0.3,
      },
    },
    exit: {
      opacity: 0,
      scale: 0.95,
      transition: { duration: 0.5 }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
    exit: {
      opacity: 0,
      y: 50,
      transition: { duration: 0.5 }
    },
  };

  return (
    <div className='md:grid grid-cols-7 px-5 md:px-0 bg-brownGrayBg'>
      <motion.div
        className='col-start-2 col-span-5 py-20 md:py-5'
        initial="hidden"
        whileInView="visible"

        variants={containerVariants}
      >
        <motion.h3
          className='text-center text-66 text-textBrown2'
          variants={itemVariants}
        >
          İncir Ebatları ve Özellikleri
        </motion.h3>
        <motion.div
          className='grid grid-cols-1 md:grid-cols-3 w-full'
          variants={containerVariants}
        >
          <motion.div
            className='flex flex-col items-center text-textBrown w-full'
            variants={itemVariants}
          >
            <motion.img
              src='/images/urunler/1.png'
              className='object-contain w-full aspect-square mb-5'
              variants={itemVariants}
            />
            <motion.h5
              className='text-40 leading-6'
              variants={itemVariants}
            >
              Jumbo Boy
            </motion.h5>
            <motion.h6
              className='text-32'
              variants={itemVariants}
            >
              Ballı incir
            </motion.h6>
          </motion.div>

          <motion.div
            className='flex flex-col items-center text-textBrown w-full'
            variants={itemVariants}
          >
            <motion.img
              src='/images/urunler/3.png'
              className='object-contain w-full aspect-square mb-5'
              variants={itemVariants}
            />
            <motion.h5
              className='text-40 leading-6'
              variants={itemVariants}
            >
             Büyük Boy
            </motion.h5>
            <motion.h6
              className='text-32'
              variants={itemVariants}
            >
              Ballı incir
            </motion.h6>
          </motion.div>

          <motion.div
            className='flex flex-col items-center text-textBrown w-full'
            variants={itemVariants}
          >
            <motion.img
              src='/images/urunler/2.png'
              className='object-contain w-full aspect-square mb-5'
              variants={itemVariants}
            />
            <motion.h5
              className='text-40 leading-6'
              variants={itemVariants}
            >
              Orta Boy
            </motion.h5>
            <motion.h6
              className='text-32'
              variants={itemVariants}
            >
              Ballı incir
            </motion.h6>
          </motion.div>
        </motion.div>

        <motion.div
          className='flex justify-center my-14'
          variants={itemVariants}
        >
            <div className='hover:scale-105 inline-block transition-all duration-300  '>
          <Link to={'/incir'} className='text-25 bg-[#E7912E] text-[#6B391A] inline-block px-6 py-2 rounded-3xl'>
            Devamı için
          </Link>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
}

export default Section3;
