import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useLenis } from '@studio-freight/react-lenis';
import Header from '../components/header/Header';
import Footer from '../components/Footer';
import HeaderMobile from '../components/header/HeaderMobile';

const PageContainer = () => {
  const location = useLocation();
  const lenis = useLenis(); // Initialize Lenis
  const [toggle, setToggle] = useState(false);

  const pageVariants = {
    initial: { translateY: -10, opacity: 0 },
    in: { translateY: 0, opacity: 1 },
    out: { translateY: 10, opacity: 0 }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'easeIn',
    duration: 1
  };

  useEffect(() => {
    if (lenis) {
      lenis.scrollTo(0);
    }
  }, [location]);

  return (
    <div className='relative'>
      <Header />

      <div className='fixed bottom-0 z-[100] pb-5 pr-5  flex right-0 hover:scale-105 transition-all duration-500 hover:animate-pulse'>
        <a href='https://wa.me/+905339298786'>
          <img className='w-14' src='/images/social/w.svg' alt='WhatsApp' />
        </a>
      </div>

      <div className='relative z-40 bg-white overflow-hidden'>
        <motion.div
          key={location.pathname}
          initial='initial'
          animate='in'
          exit='out'
          variants={pageVariants}
          transition={pageTransition}
      
          
        >
          <Outlet />
        </motion.div>
        <HeaderMobile toggle={toggle} />
      </div>
      <Footer />
    </div>
  );
};

export default PageContainer;
