import React from 'react'

const Footer = () => {
  return (
    <div className='md:grid md:grid-cols-7  bg-[#EEE1C9] py-20 px-5 md:px-0'>
      <div className=' md:col-start-2 md:col-span-5  grid grid-cols-1 md:grid-cols-3  '>
        <div className='  md:w-max flex flex-col items-center'>

          <img className='w-48 mb-10' src='/images/logo/logo-brown.svg' />
          <a href='mailto:info@koyicinden.com.tr' className='block mb-10 transition-all duration-300 hover:text-textOrange'>info@koyicinden.com.tr</a>
          <div className=' flex flex-col items-center mb-10 md:mb-0' >
            <p className='block text-25'>Mesai Saatlerimiz</p>
            <p className='block text-26'>08:30 - 18:00</p>

          </div>
        </div>
        <div className=' text-26 text-textBrown flex flex-col justify-center items-center mb-10 md:mb-0'>
          <div className='flex flex-col items-center'>
            <p className='block leading-3'>Sosyal Medyadan</p>
            <p className='block mb-3'>Bizi Takip edebilirsiniz</p>
            <div className='flex justify-around w-full'>
              <a target='_blank' href='https://www.facebook.com/people/K%C3%B6y-%C4%B0%C3%A7i/61564133326730/'><img className='w-6 transition-all duration-300 hover:scale-105 cursor-pointer' src='/images/social/f.svg' /></a>
              <a target='_blank' href='https://www.instagram.com/koyicindenatural/'><img className='w-11 transition-all duration-300 hover:scale-105 cursor-pointer' src='/images/social/i.svg' /></a>
            </div>

          </div>


        </div>
        <div className=' text-26 text-textBrown flex flex-col justify-center items-center'>
          <div className='flex flex-col items-center'>
            <p className='block leading-3'>WhatsApp üzerinden</p>
            <p className='block mb-3'>siparişlerinizi verebilirsiniz.</p>
            <a target='_blank' href='https://wa.me/+905339298786' className='flex justify-around items-center w-full space-x-2 transition-all duration-300 hover:scale-105 cursor-pointer group '>
              <img className='w-12 ' src='/images/social/w.svg' />
              <a  className='block text-2xl md:text-2xl text-black md:leading-normal group-hover:text-textOrange transition-all duration-300'>0533 929 87 86</a>
            </a>

          </div>


        </div>
      </div>
    </div>

  )
}

export default Footer