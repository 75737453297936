// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';

export default () => {
  return (
    <Swiper
      spaceBetween={0}
      className='h-[102vh]'
      modules={[EffectFade, Autoplay]}
      effect="fade"
      speed={2000}
      fadeEffect={{ crossFade: true }}
      autoplay={{ delay: 3000 }}
      loop={true} // Enable looping
    >
      <SwiperSlide>
        <div className='h-full w-full  relative'>
          <img src='/images/slider/1.jpg' className='h-full w-full object-cover' />
          <div className='absolute h-full w-full md:grid grid-cols-7 top-0 left-0 '>
            <div className=' col-start-2 col-span-5  h-full flex items-end pb-20 ml-5 md:ml-0 '>
              <div className='w-64 h-64 md:h-[27rem] md:w-[27rem] bg-[#E7912E] rounded-full flex justify-center items-center text-center '>
                <h2 className=' md:leading-7 text-sm md:text-36 text-white'>
                  Sarılop inciri, özellikle
                  <br/>
                  Aydın ve çevresinde yaygın
                  <br/>
                  olarak yetiştirilir.
                  <br/>
                  Basık-yuvarlak şekli, sarı
                  <br/>
                  rengi ve koyu kırmızı meyve
                  <br/>
                  etiyle tanınır. Bol çekirdekli
                  <br/>
                  dolgun ve tatlı yapısıyla
                  <br/>
                  dikkat çeken 
                  <br/>
                  bir incir türüdür.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='h-full w-full  relative'>
          <img src='/images/slider/2.jpg' className='h-full w-full object-cover' />
          <div className='absolute h-full w-full md:grid grid-cols-7 top-0 left-0 '>
            <div className=' col-start-2 col-span-5  h-full flex items-end pb-20 ml-5 md:ml-0 '>
              <div className='w-64 h-64 md:h-[27rem] md:w-[27rem] bg-[#E7912E] rounded-full flex justify-center items-center text-center '>
                <h2 className=' md:leading-7 text-sm md:text-36 text-white'>
                  Ağaçlardan
                  <br/>
                  toplanan incirlerin
                  <br/>
                  olgunlaşma süreci ve
                  <br/>
                  kurutma dönemi
                  <br/>
                  boyunca esen rüzgarlar
                  <br/>
                  Aydın'ın incirini
                  <br/>
                  diğerlerinden ayıran önemli
                  <br/>
                faktörler arasında
                <br/>
                yer alır.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};
