import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const Section1 = () => {
    const containerVariants = {
        hidden: { opacity: 0, scale: 0.95 },
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                duration: 1.5,
                staggerChildren: 0.5,
            },
        },
        exit: {
            opacity: 0,
            scale: 0.95,
            transition: { duration: 1.5 },
        },
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 1.5,
            },
        },
        exit: {
            opacity: 0,
            y: 50,
            transition: { duration: 1.5 },
        },
    };

    return (
        <motion.div
            initial="hidden"
            whileInView="visible"
       
            variants={containerVariants}
            className='grid grid-cols-7 bg-[#F7F2E9]'
        >
            <div className='col-span-7 md:col-span-3 md:grid md:grid-cols-3 px-5'>
                <motion.div
                    className='col-start-2 col-span-2 flex md:items-center'
                    variants={containerVariants}
                >
                    <div className='relative py-20 md:py-10'>
                 
                        <motion.h1
                            className='text-[3rem] text-[#6B391A] mb-4'
                            variants={itemVariants}
                        >
                            Hikayemiz
                        </motion.h1>
                        <motion.p
                            className='text-[1rem] text-[#4D3F27] mb-8 w-[100%] md:w-[100%]'
                            variants={itemVariants}
                        >
                            Nazilli'nin bereketli köylerinde, doğanın eşsiz
                            güzellikleri arasında serpilip büyüyen ballı
                            incirlerin hikayesi, yüzyıllardır süregelen bir
                            mirasın izlerini taşır. Dağların serin esintileriyle,
                            güneşin cömert ışıklarıyla olgunlaşan bu özel
                            incirler, köy halkının emekleriyle birleşerek
                            adeta yaradanın birer armağanı haline gelir.
                            Her bir meyve, Nazilli'nin verimli topraklarında
                            kök salmış geleneklerin ve mükemmel doğanın
                            izlerini taşır. İdealist bir öğretmen ve girişimci
                            bir ruha sahip olan marka kurucumuz, bu
                            topraklarda doğup büyüyen, çocukluğu
                            Nazilli'nin meyve bahçelerinde, ballı incirlerin
                            gölgesinde geçen biridir.
                        </motion.p>
                        <Link to={'/hikayemiz'} >
                        <div className='hover:scale-105 inline-block transition-all duration-300  '>
                        <motion.div
                            className='text-25 bg-[#E7912E] text-[#6B391A] inline-block px-6 py-2 rounded-3xl'
                            variants={itemVariants}
                        >
                            Devamı için
                        </motion.div>
                        </div>
                        </Link>
                    </div>
                </motion.div>
            </div>
            <div className='col-span-7 md:col-span-4 relative'>
                <motion.img
                className='object-contain w-full'
                    src='/images/anasayfa/section1/back.png'
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 1.5 }}
                    variants={itemVariants}
                />
                <motion.img
                    src='/images/anasayfa/section1/photo1.png'
                    className='absolute top-0 left-0 object-contain w-full'
                    initial={{ opacity: 0, y: -50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -50 }}
                    transition={{ duration: 1.5, delay: 0.5 }}
                    variants={itemVariants}
                />
                <motion.img
                    src='/images/anasayfa/section1/photo2.png'
                    className='absolute top-0 left-0 object-contain w-full'
                    initial={{ opacity: 0, y: -50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -50 }}
                    transition={{ duration: 1.5, delay: 1 }}
                    variants={itemVariants}
                />
            </div>
        </motion.div>
    );
}

export default Section1;
