import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const Section2 = () => {
    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                duration: 1.5,
                staggerChildren: 0.5,
            },
        },
        exit: { opacity: 0, transition: { duration: 1.5 } },
    };

    const itemVariants = {
        hidden: { opacity: 0, y: -50 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 1.5,
            },
        },
        exit: {
            opacity: 0,
            y: -50,
            transition: { duration: 1.5 },
        },
    };

    return (
        <motion.div
            initial="hidden"
            whileInView="visible"

            variants={containerVariants}
            className='grid grid-cols-7 bg-[#593F27]'
        >
            <div className='col-span-7 md:col-span-3 md:grid md:grid-cols-3'>
                <div className='col-start-2 col-span-2 flex items-center px-5 md:px-0'>
                    <div className='relative py-20 md:py-5'>
                        <motion.img
                            className='w-44 mb-10'
                            src='/images/logo/logo.svg'
                            variants={itemVariants}
                        />
                        <motion.h3
                            className='text-50 text-textOrange mb-5'
                            variants={itemVariants}
                        >
                            Aydın İnciri'nin <br /> (Sarılop)  Farkı
                        </motion.h3>
                        <motion.p
                            className='text-22 text-white mb-8 w-[100%] md:w-[80%]'
                            variants={itemVariants}
                        >
                            Aydın inciri, Anadolu orijinli Ficus carica türünün
                            Sarılop çeşidi olarak bilinir ve özellikle kurutmalık
                            olarak değerlendirilir. Bu incir türü, düzgün
                            yapısı, iri boyutları, yoğun ballı ve kıvamlı iç
                            yapısıyla dikkat çeker. Ağızda bıraktığı yoğun ve
                            kendine has aroması, tatlı kokusuyla farklı bir
                            lezzet sunar.
                        </motion.p>
                        <div className='hover:scale-105 inline-block transition-all duration-300 '>
                        <motion.div
                      
                            variants={itemVariants}
                        >
                        <Link
                            to={'sari-lop'}
                            className='text-25 bg-[#E7912E] text-[#6B391A] inline-block px-6 py-2 rounded-3xl'
                        >
                            Devamı için
                        </Link>
                        </motion.div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-span-7 md:col-span-4 relative'>
                <motion.img
                className='object-contain w-full'
                    src='/images/anasayfa/section2/back.png'
                    variants={itemVariants}
                />
                <motion.img
                    src='/images/anasayfa/section2/photo1.png'
                    className='absolute top-0 left-0 object-contain w-full'
                    variants={itemVariants}
                />
                <motion.img
                    src='/images/anasayfa/section2/photo2.png'
                    className='absolute top-0 left-0 object-contain w-full'
                    variants={itemVariants}
                    transition={{ delay: 0.5 }}
                />
                <motion.img
                    src='/images/anasayfa/section2/photo3.png'
                    className='absolute top-0 left-0 object-contain w-full'
                    variants={itemVariants}
                    transition={{ delay: 1 }}
                />
                <motion.img
                    src='/images/anasayfa/section2/photo4.png'
                    className='absolute top-0 left-0 object-contain w-full'
                    variants={itemVariants}
                    transition={{ delay: 1.5 }}
                />
            </div>
        </motion.div>
    );
}

export default Section2;
