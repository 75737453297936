import React from 'react';
import { motion } from 'framer-motion';

const SariLop = () => {
    const containerVariants = {
        hidden: { opacity: 0, scale: 0.95 },
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                duration: 1.5,
                staggerChildren: 0.5,
            },
        },
        exit: {
            opacity: 0,
            scale: 0.95,
            transition: { duration: 1.5 },
        },
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 1.5,
            },
        },
        exit: {
            opacity: 0,
            y: 50,
            transition: { duration: 1.5 },
        },
    };

    return (
        <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={containerVariants}
        >
            <div>
                <motion.img
                    src='/images/banner/sari-lop.jpg'
                    className='h-[48vh] w-full object-cover'
                    variants={itemVariants}
                />
            </div>

            <div className='md:grid md:grid-cols-14 bg-white'>
                <div className='w-full md:col-span-7 md:grid md:grid-cols-7 py-20 px-5 md:mx-0'>
                    <motion.div className='col-start-3 col-span-5 flex flex-col' variants={containerVariants}>
                        <div className='mb-5'>
                            <motion.h2 className='text-textBrown2 text-54 !block leading-tight' variants={itemVariants}>
                                Aydın İnciri'nin <br /> <span className='text-textOrange'>(SarıLop)</span> Farkı
                            </motion.h2>
                        </div>

                        <div className='flex flex-col mb-5'>
                            <div className='mb-5'>
                                <motion.h2 className='text-textOrange text-36 !block leading-8' variants={itemVariants}>
                                Aydın İnciri'ni <br /> Farklı Kılan Özellikler
                                </motion.h2>
                            </div>
                            <motion.p className='!block text-22' variants={itemVariants}>
                            Aydın inciri, Anadolu orijinli Ficus carica türünün Sarılop çeşidi olarak bilinir ve özellikle kurutmalık olarak değerlendirilir. Bu incir türü, düzgün yapısı, iri boyutları, yoğun ballı ve kıvamlı iç yapısıyla dikkat çeker. Ağızda bıraktığı yoğun ve kendine has aroması, tatlı kokusuyla farklı bir lezzet sunar.

                            </motion.p>
                        </div>

                        <div className='flex flex-col mb-5'>
                            <div className='mb-5'>
                                <motion.h2 className='text-textOrange text-36 !block leading-8' variants={itemVariants}>
                                    Geleneksel Yöntemlerle <br /> Elde Edilen Kalite
                                </motion.h2>
                            </div>
                            <motion.p className='!block text-22' variants={itemVariants}>
                            Binlerce yıldır, Aydın yöresinin verimli topraklarında yetiştirilen incirin hasadı ve kurutulması, geleneksel yöntemlerle yapılmaktadır. İncir yetiştiriciliği, arazi hazırlama, budama, döllemenin incelikleri ve hasat işlemleri gibi uzmanlık isteyen bir süreçtir. Bu süreçlerde kullanılan makine ve yöntemler, Aydın incirinin kalitesini doğrudan etkiler. Ağaçlardan toplanan incirlerin, olgunlaşma süreci ve kurutma dönemi boyunca esen rüzgarlar, Aydın'ın incirini diğerlerinden ayıran önemli faktörler arasında yer alır.
                            </motion.p>
                        </div>

                        <div className='flex flex-col'>
                            <div className='mb-5'>
                                <motion.h2 className='text-textOrange text-36 !block leading-8' variants={itemVariants}>
                                    Sarılop: Lezzet ve Şeklin<br />Mükemmel Uyumu
                                </motion.h2>
                            </div>
                            <motion.p className='!block text-22' variants={itemVariants}>
                            Sarılop inciri, özellikle Aydın ve çevresinde yaygın olarak yetiştirilir. Basık-yuvarlak şekli, sarı rengi ve koyu kırmızı meyve etiyle tanınır. Bol çekirdekli, dolgun ve tatlı yapısıyla dikkat çeken bu incir, genellikle susuz şartlarda yetiştirilir ve kurutmalık olarak değerlendirilir. Sarılop’un ayırt edici özelliği, hem şeklinin hem de lezzetinin uyumlu lezzetinde saklıdır.
                            </motion.p>
                        </div>
                    </motion.div>
                </div>

                <div className='col-span-7 md:col-span-7 relative'>
                    <motion.div
                        className='col-start-2 col-span-3'
                        initial="hidden"
                        whileInView="visible"
                        exit="exit"
                        variants={containerVariants}
                    >
                        <motion.img
                            src='/images/sari-lop/back.png'
                            className='w-full'
                            variants={itemVariants}
                        />
                        <motion.img
                            src='/images/sari-lop/1.png'
                            className='absolute top-0 left-0 w-full object-contain'
                            variants={itemVariants}
                            initial={{ opacity: 0, y: -50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1.5, delay: 0.5 }}
                        />
                        <motion.img
                            src='/images/sari-lop/2.png'
                            className='absolute top-0 left-0 w-full object-contain'
                            variants={itemVariants}
                            initial={{ opacity: 0, y: -50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1.5, delay: 1 }}
                        />
                        <motion.img
                            src='/images/sari-lop/3.png'
                            className='absolute top-0 left-0 w-full object-contain'
                            variants={itemVariants}
                            initial={{ opacity: 0, y: -50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1.5, delay: 1.5 }}
                        />
                        <motion.img
                            src='/images/sari-lop/4.png'
                            className='absolute top-0 left-0 w-full object-contain'
                            variants={itemVariants}
                            initial={{ opacity: 0, y: -50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1.5, delay: 2 }}
                        />
                        <motion.img
                            src='/images/sari-lop/5.png'
                            className='absolute top-0 left-0 w-full object-contain'
                            variants={itemVariants}
                            initial={{ opacity: 0, y: -50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1.5, delay: 2.5 }}
                        />
                    </motion.div>
                </div>
            </div>
        </motion.div>
    );
};

export default SariLop;
