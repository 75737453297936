import React from "react";
import { motion } from "framer-motion";

const Incir = () => {
  const containerVariants = {
    hidden: { opacity: 0, scale: 0.95 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
        staggerChildren: 0.3,
      },
    },
    exit: {
      opacity: 0,
      scale: 0.95,
      transition: { duration: 0.5 },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
    exit: {
      opacity: 0,
      y: 50,
      transition: { duration: 0.5 },
    },
  };

  return (
    <div>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <motion.div variants={itemVariants}>
          <img
            src="/images/banner/incir.jpg"
            className="h-[48vh] w-full object-cover"
          />
        </motion.div>
        <div className="md:grid grid-cols-7 px-5 md:px-0 bg-brownGrayBg">
          <motion.div
            className="col-start-2 col-span-5 pt-20 md:py-5"
            variants={containerVariants}
          >
            <motion.h3
              className="text-center text-66 text-textBrown2"
              variants={itemVariants}
            >
              İncir Ebatları ve Özellikleri
            </motion.h3>
            <motion.div
              className="grid grid-cols-1 md:grid-cols-3 w-full"
              variants={containerVariants}
            >
              <motion.div
                className="flex flex-col items-center text-textBrown w-full"
                variants={itemVariants}
              >
                <motion.img
                  src="/images/urunler/1.png"
                  className="object-contain w-full aspect-square mb-5"
                  variants={itemVariants}
                />
                <motion.h5
                  className="text-40 leading-6"
                  variants={itemVariants}
                >
                  Jumbo Boy
                </motion.h5>
                <motion.h6 className="text-32" variants={itemVariants}>
                  Ballı incir
                </motion.h6>
              </motion.div>

              <motion.div
                className="flex flex-col items-center text-textBrown w-full"
                variants={itemVariants}
              >
                <motion.img
                  src="/images/urunler/3.png"
                  className="object-contain w-full aspect-square mb-5"
                  variants={itemVariants}
                />
                <motion.h5
                  className="text-40 leading-6"
                  variants={itemVariants}
                >
                  Büyük Boy
                </motion.h5>
                <motion.h6 className="text-32" variants={itemVariants}>
                  Ballı incir
                </motion.h6>
              </motion.div>

              <motion.div
                className="flex flex-col items-center text-textBrown w-full"
                variants={itemVariants}
              >
                <motion.img
                  src="/images/urunler/2.png"
                  className="object-contain w-full aspect-square mb-5"
                  variants={itemVariants}
                />
                <motion.h5
                  className="text-40 leading-6"
                  variants={itemVariants}
                >
                  Orta Boy
                </motion.h5>
                <motion.h6 className="text-32" variants={itemVariants}>
                  Ballı incir
                </motion.h6>
              </motion.div>
            </motion.div>
          </motion.div>

          <motion.div
            className="col-start-2 col-span-5 pt-20 pb-10 text-66 text-textBrown2 text-center"
            variants={containerVariants}
          >
            Aydın Sarılop Kuru İncirin Diğer İncirlerden Farkı
          </motion.div>

          <motion.div
            className="col-start-2 col-span-5 pb-20 grid grid-cols-1 md:grid-cols-2"
            variants={containerVariants}
          >

            <motion.div className="flex flex-col mb-5 md:pr-5" variants={itemVariants}>
              <motion.h6
                className="text-36 text-textOrange leading-[1.2]"
                variants={itemVariants}
              >
                Yoğun ve Kıvamlı İç Yapısı

              </motion.h6>
              <motion.p
                className="text-22 text-textBrown leading-snug"
                variants={itemVariants}
              >
                Aydın Sarılop inciri, yoğun ballı ve kıvamlı iç yapısıyla diğer incirlerden ayrılır. Bu, ağızda bıraktığı kendine has aroma ile farklı bir lezzet sunar.
              </motion.p>
            </motion.div>

            <motion.div className="flex flex-col mb-5" variants={itemVariants}>
              <motion.h6
                className="text-36 text-textOrange leading-[1.2]"
                variants={itemVariants}
              >
                Geleneksel Üretim Yöntemleri
              </motion.h6>
              <motion.p
                className="text-22 text-textBrown leading-snug"
                variants={itemVariants}
              >
                Aydın yöresinin verimli topraklarında yetişen incirlerin binlerce yıllık geleneksel yöntemlerle hasat edilmesi, bu incirin kalitesini ve lezzetini belirgin kılar.
              </motion.p>
            </motion.div>

            <motion.div className="flex flex-col mb-5 md:pr-5" variants={itemVariants}>
              <motion.h6
                className="text-36 text-textOrange leading-[1.2]"
                variants={itemVariants}
              >
                Kurutma Sürecindeki Önemli Faktörler
              </motion.h6>
              <motion.p
                className="text-22 text-textBrown leading-snug"
                variants={itemVariants}
              >
                Aydın Sarılop’un olgunlaşma ve kurutma sürecinde esen rüzgarlar, bu incirin ayırt edici özelliklerini belirleyen en önemli faktörlerden biridir.
              </motion.p>
            </motion.div>

            <motion.div className="flex flex-col mb-5" variants={itemVariants}>
              <motion.h6
                className="text-36 text-textOrange leading-[1.2]"
                variants={itemVariants}
              >
                Susuz Şartlarda Yetişme Özelliği
              </motion.h6>
              <motion.p
                className="text-22 text-textBrown leading-snug"
                variants={itemVariants}
              >
                Sarılop inciri, Aydın'ın sıcak ve kurak koşullarına uyum sağlayarak susuz şartlarda yetişebilir. Bu durum, incirin tatlı ve yoğun yapısını destekler.

              </motion.p>
            </motion.div>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default Incir;
