import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const DATA = [
  { name: 'Anasayfa', link: '/' },
  { name: 'Hikayemiz', link: '/hikayemiz' },
  { name: 'Neden Sarılop İnciri?', link: '/sari-lop' },
  { name: 'Aydın İnciri ve Özellikleri', link: '/incir' },
];

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);

  const isMobile = () => window.innerWidth < 1024;

  const handleLinkClick = (link) => {
    if (isMobile()) {
      setToggle(false);
      setTimeout(() => {
        navigate(link);
      }, 500); // Match this duration with the transition duration
    } else {
      navigate(link); // Direct navigation on non-mobile devices
    }
  };

  const handleToggle = () => {
    if (isMobile()) {
      setToggle(!toggle);
    }
  };

  return (
    <div className="h-28 w-full absolute top-0 z-50 customGradient grid grid-cols-7 px-5">
      <Link to="/" className="md:col-start-2 col-span-3 md:col-span-2 py-5 block">
        <img src="/images/logo/logo.svg" className="w-48" />
      </Link>
      <div className="col-span-3 justify-between items-center hidden lg:flex">
        {DATA.map((item, index) => (
          <Link
            key={index}
            to={item.link}
            className={`text-22 hover:scale-105 transition-all duration-300 hover:text-textOrange ${
              location.pathname === item.link ? 'text-headerOrange' : 'text-white'
            }`}
          >
            {item.name}
          </Link>
        ))}
      </div>

      <div className="col-span-4 justify-end items-center flex lg:hidden">
        <div onClick={handleToggle} className="h-8 w-12 relative">
          <div
            className={`absolute w-10 h-1 rounded-xl bg-white transition-all duration-500 ${
              toggle ? 'top-3.5 -rotate-45' : 'top-0'
            }`}
          ></div>
          <div
            className={`absolute w-10 h-1 rounded-xl bg-white transition-all duration-500 ${
              toggle ? 'top-3.5 translate-x-60' : 'top-3.5'
            }`}
          ></div>
          <div
            className={`absolute w-10 h-1 rounded-xl bg-white transition-all duration-500 ${
              toggle ? 'top-3.5 rotate-45' : 'top-7'
            }`}
          ></div>
        </div>
      </div>

      <div
        className={`col-span-7 transition-all duration-500 flex flex-col justify-center items-center overflow-hidden bg-textOrange bg-blend-multiply h-[30vh] space-y-4 ${
          toggle ? 'translate-x-0 opacity-100' : 'translate-x-[200%] opacity-0'
        } ${toggle ? 'rotate-0' : 'rotate-45'}`}
      >
        {DATA.map((item, index) => (
          <span
            key={index}
            className={`text-25 text-white !block ${
              location.pathname === item.link ? 'text-headerOrange' : 'text-white'
            }`}
            onClick={() => handleLinkClick(item.link)}
          >
            {item.name}
          </span>
        ))}
      </div>
    </div>
  );
};

export default Header;
