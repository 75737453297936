export const DATA = [
    {
      title: 'ANASAYFA',
      link: '/'
    },
    {
      title: 'KURUMSAL',
      link: '/kurumsal',
      
  
    },
    {
      title: 'ÜRÜNLER',
      link: '/urunler/ekskavator-urunleri',
      dropDown: [
        {
          title: 'Ekskavatör Ürünleri',
          link: '/urunler/ekskavator-urunleri',
        },
        {
          title: 'Yükleyici Ürünleri',
          link: '/urunler/yukleyici-urunleri',
        },
        {
          title: 'Beko Kazıcı Ürünleri',
          link: '/urunler/beko-kazici-yukleyici',
        },
        {
            title: 'Özel Üretim Ürünleri',
            link: '/urunler/ozel-urunler',
        },
  
      ]
  
    },


    {
      title: 'HİZMETLERİMİZ',
      link: '/hizmetlerimiz',
      dropDown: [
        {
          title: 'Yedek Parça',
          link: '/hizmetlerimiz',
        },
        {
          title: 'Yerinde Servis - Tamirat',
          link: '/hizmetlerimiz',
        },
        {
          title: 'Ekipman Satış',
          link: '/hizmetlerimiz',
        },
        // {
        //   title: 'Devam Eden Projeler',
        //   link: '/devam-eden-projeler',
        // },
  
      ]
    },

    {
        title: 'REFERANSLAR',
        link: '/referanslar',
    
      },

    {
        title: 'BLOG',
        link: '/blog',
    
      },
    {
      title: 'İLETİŞİM',
      link: '/iletisim',
  
    },
  
  
  ]