
import Anasayfa from './pages/Anasayfa';
import { AnimatePresence } from 'framer-motion';
import { ReactLenis } from '@studio-freight/react-lenis'
import { Route, Routes, useLocation } from "react-router-dom";
import PageContainer from "./pages/PageContainer";
import SariLop from './pages/SariLop';
import Hikayemiz from './pages/Hikayemiz';
import Incir from './pages/Incir';


function App() {
  const location = useLocation();
  return (
    <ReactLenis root>
      <div className="App relative overflow-x-hidden ">
        <AnimatePresence mode="wait">
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<PageContainer />} >
              <Route path="/" element={<Anasayfa />} />
              <Route path="/sari-lop" element={<SariLop />} />
              <Route path="/hikayemiz" element={<Hikayemiz />} />
              <Route path="/incir" element={<Incir />} />

            </Route>
          </Routes>
        </AnimatePresence>
      </div>
    </ReactLenis>
  );
}

export default App;
