import React from 'react'
import { motion, AnimatePresence } from 'framer-motion';
import Slider from '../components/Slider';
import Section1 from '../components/anasayfa/Section1';
import Section2 from '../components/anasayfa/Section2';
import Section3 from '../components/anasayfa/Section3';

const Anasayfa = () => {
  return (
    <>

<Slider/>
<Section1/>
<Section2/>
<Section3/>
</>
  )
}

export default Anasayfa