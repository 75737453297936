
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import { DATA } from '../../data/header-data'




const HeaderMobile = ({toggle}) => {
  const {pathname} = useLocation()
  return (
    <div className={`h-full bg-liderBlue pt-24 w-full z-[100] top-0 left-0 fixed ${toggle?'translate-x-[0%]':'-translate-x-[100%]'}  transition-all duration-500 flex flex-col justify-between`}>
      <div className='h-full w-full bg-ulusOranges px-[16.6%] py-10'>
        {
          DATA.map((item,index)=>{
            return(
              <div key={index} className='text-white text-21 font-bold '>
                <div className=' mb-5'>
                <Link to={item.link} className='block'>{item.title}</Link>
                <div className={`h-1 ${item.link === pathname  ? 'bg-white w-9' : 'bg-none w-0'}`}></div>
                </div>
                <div className='ml-5 mb-5'>
                  {
                    item.dropDown?.map((item,index)=>{
                      return(
                        <Link to={item.link} className='block '>{item.title}</Link>
                      )
                    })
                  }
                </div>
                </div>
            )
          })
        }
                <a
          href="tel:05066500602"
          className="col-span-1 text-14  items-center justify-start space-x-2 flex text-white text-21 font-bold "
        >
          <img className="h-5 w-5" src="images/icons/tel-logo-white.svg" />
          {/* <span>0 506 650 06 02</span> */}
        </a>

        </div>

    </div>
  )
}

export default HeaderMobile